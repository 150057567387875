import styled from 'styled-components';

import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import IconButton from '../atoms/IconButton';
import Header from '../components/Header';
import Avatar from '../atoms/Avatar';
import Button from '../atoms/Button';

import { AuthContext } from '../utilities/AuthContext';

import { requests } from '../utilities/Api';
import { opacity } from 'styles/MotionVariants';

const StyledContainer = styled.article`
  h2 {
    color: var(--clr-text-primary);
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    .avatar {
      z-index: 0;
      transition: transform 100ms;
    }

    .avatar:hover{
      transform: scale(1.1);
    }

    .avatar:active {
      transform: scale(0.9);
    }

    li {
      position: relative;
    }

    li::after {
      content: '';
      display: block;
      position: absolute;
      border: 5px solid var(--clr-text-primary);
      border-radius: 100%;
      opacity: 0;
      inset: -8px -8px -8px -8px;
      transform: scale(0);
      transition: transform 300ms, opacity 300ms;
    }

    li.selected::after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const { initial, animate, container, item } = opacity;


export default function ChangeAvatar() {

  const { user: { avatar }, setUser, setPopupMessage } = useContext(AuthContext);
  const [selected, setSelected] = useState(avatar);

  async function saveAvatar(ev) {

    if (avatar === selected) {
      return
    };

    ev.preventDefault();

    let response;

    try {
      response = (await requests.post('account', {
        change: 'avatar',
        avatar: selected,
      })).data;
    } catch(err) {
      console.log(err.response);
      return;
    }

    setPopupMessage(response);
    setUser(user => ({...user, avatar: selected}));
  }

  return (
    <>
      <Header title='Change avatar'
        leftButton={(<Link to='/account/settings'><IconButton icon={<FontAwesomeIcon icon={faArrowLeft} color='#fff' transform='grow-10' />} /></Link>)}
        rightButton={<Button title='Save' handler={saveAvatar} />}
      />
      <StyledContainer>
        <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>Choose your avatar:</motion.h2>
        <motion.ul variants={container} initial={initial} animate={animate}>
          <motion.li variants={item} className={selected === 1 ? 'selected' : undefined} key={1} onClick={() => setSelected(1)} >
            <Avatar avatar={1} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 2 ? 'selected' : undefined} key={2} onClick={() => setSelected(2)} >
            <Avatar avatar={2} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 3 ? 'selected' : undefined} key={3} onClick={() => setSelected(3)} >
            <Avatar avatar={3} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 4 ? 'selected' : undefined} key={4} onClick={() => setSelected(4)} >
            <Avatar avatar={4} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 5 ? 'selected' : undefined} key={5} onClick={() => setSelected(5)} >
            <Avatar avatar={5} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 6 ? 'selected' : undefined} key={6} onClick={() => setSelected(6)} >
            <Avatar avatar={6} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 7 ? 'selected' : undefined} key={7} onClick={() => setSelected(7)} >
            <Avatar avatar={7} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 8 ? 'selected' : undefined} key={8} onClick={() => setSelected(8)} >
            <Avatar avatar={8} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 9 ? 'selected' : undefined} key={9} onClick={() => setSelected(9)} >
            <Avatar avatar={9} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 10 ? 'selected' : undefined} key={10} onClick={() => setSelected(10)} >
            <Avatar avatar={10} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 11 ? 'selected' : undefined} key={11} onClick={() => setSelected(11)} >
            <Avatar avatar={11} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 12 ? 'selected' : undefined} key={12} onClick={() => setSelected(12)} >
            <Avatar avatar={12} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 13 ? 'selected' : undefined} key={13} onClick={() => setSelected(13)} >
            <Avatar avatar={13} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 14 ? 'selected' : undefined} key={14} onClick={() => setSelected(14)} >
            <Avatar avatar={14} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 15 ? 'selected' : undefined} key={15} onClick={() => setSelected(15)} >
            <Avatar avatar={15} size='medium' />
          </motion.li>
          <motion.li variants={item} className={selected === 16 ? 'selected' : undefined} key={16} onClick={() => setSelected(16)} >
            <Avatar avatar={16} size='medium' />
          </motion.li>
        </motion.ul>
      </StyledContainer>
    </>
  );
}