import styled from 'styled-components';

import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Spinner from 'atoms/Spinner';
import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Header from 'components/Header';
import MovieCard from 'components/MovieCard';
import Lists from 'components/modals/Lists';
import MessageBox from 'components/MessageBox';

import { requests } from 'utilities/Api';
import { opacity } from 'styles/MotionVariants';

const StyledFormContainer = styled.div`
  form {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;

    div {
      flex: 1;
    }
  }

  p {
    font-size: 1rem;
    color: var(--clr-text-secondary);
    margin-top: 1.5rem;
  }

  @media (min-width: 600px) {
    form {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1.5rem;

      div {
        max-width: 50ch;
      }
    }
    p {
      font-size: 1rem;
      margin-top: 2rem;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

export default function Search() {

  const [prevTitle, setPrevTitle] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [modalInfo, setModalInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [movies, setMovies] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    page: 1,
    totalResults: -1,
    maxPage: 1
  });

  useEffect(() => {
    if (!loading) {
      return
    }
    
    updateMovies(false);
  }, [resultInfo]);

  function handleSubmit(ev) {
    ev.preventDefault();
    if (!searchTitle || searchTitle.trim() === prevTitle.trim()) {
      return;
    }
    
    setLoading(true);
    updateMovies(true);
  }
  
  function handlePagination(ev) {
    setLoading(true);
    setResultInfo(info => ({
      ...info, 
      page: (ev.target.value === 'next') ? info.page + 1 : info.page - 1
    }));
  }
  
  async function updateMovies(changeInfo) {
    setPrevTitle(searchTitle.trim());

    setMovies([]);
    setLoading(true);
    setError(false);

    let response;

    try {
      response = (await requests.get(`search?title=${searchTitle.trim()}&page=${changeInfo ? 1 : resultInfo.page}`)).data;
    } catch(err) {
      console.log('error:', err.response);
      setLoading(false);
      return;
    }
    
    setLoading(false);

    if (!response) {
      setError(true);
      return;
    }
    
    setMovies(response.movies);
    
    if (changeInfo) {
      setResultInfo(info => ({
        page: 1,
        totalResults: response.totalResults,
        maxPage: Math.ceil(response.totalResults / 10)
      }));
    }
  }

  return (
    <>
      <Header title='Search' />
      <StyledFormContainer>
        <form onSubmit={handleSubmit}>
          <Input value={searchTitle} setValue={setSearchTitle} 
            placeholder='Input something...' icon={faSearch} id='search-input' />
          <Button title='Search' />
        </form>
        {!movies.length
          ? null
          : (<p>Showing {`${resultInfo.page * 10 - 9}-${resultInfo.page * 10 - 10 + movies.length}`} of {resultInfo.totalResults} results.</p>)}
      </StyledFormContainer>
      {!loading && !error && !movies.length && (
        <MessageBox>
          Try typing a title of a movie you know in the search bar above!
        </MessageBox>
      )}
      {loading
        ? <Spinner />
        : error
          ? <MessageBox title='Not found'>"{prevTitle}" was not found. Try searching for another title!</MessageBox> 
          : (<motion.ul
              variants={opacity.container}
              initial={opacity.initial}
              animate={opacity.animate}
            >
              {movies.map((movie, index) => (
                <motion.li variants={opacity.item} key={movie.id + String(index)}>
                  <MovieCard info={movie}>
                    <Button title='Add to list' handler={() => setModalInfo({id: movie.id})} />
                  </MovieCard>
                </motion.li>
              ))}
            </motion.ul>)
      }
      {!movies.length
        ? null
        : (<StyledButtonContainer>
            <Button type='secondary' value='prev' disabled={(resultInfo.page === 1) ? true : false} handler={handlePagination} title='Prev. page' />
            <Button type='secondary' value='next' disabled={(resultInfo.page === resultInfo.maxPage) ? true : false} handler={handlePagination} title='Next page' />
          </StyledButtonContainer>)}
      <Lists modalInfo={modalInfo} setModalInfo={setModalInfo} />
    </>
  );
}
