import styled from 'styled-components';

import { useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import MessageBox from 'components/MessageBox';
import Lists from 'components/modals/Lists';
import Header from 'components/Header';
import MovieCard from 'components/MovieCard';
import Button from 'atoms/Button';
import Spinner from 'atoms/Spinner';
import Confirm from 'components/modals/Confirm';

import { requests } from 'utilities/Api';
import { AuthContext } from 'utilities/AuthContext';
import { opacity } from 'styles/MotionVariants';


const SuggestionCard = styled.div`
  background: var(--clr-background-secondary);
  border-radius: 10px;
  color: var(--clr-text-secondary);
  height: 100%;
  display: flex;
  flex-direction: column;

  & > p {
    padding: 1rem;
  }

  @media (min-width: 600px) {
    border-radius: 15px;
  }
`;

const { initial, animate, container, item } = opacity;


export default function Suggestions() {

  const { setPopupMessage } = useContext(AuthContext);

  const [modalInfo, setModalInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [movies, setMovies] = useState([]);
  const [modalRemoveInfo, setModalRemoveInfo] = useState(null);

  useEffect(() => {
    loadMovies();
  }, []);

  async function loadMovies() {
    let response;

    try {
      response = (await requests.get('suggestions')).data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
      return;
    }

    setLoading(false);

    if (response) {
      setMovies(response);
    }
  }

  async function removeMovie(info) {
    let response;

    try {
      response = (await requests.delete(`suggestions?username=${info.username}&movie=${info.movie}`)).data;
    } catch (err) {
      setPopupMessage(err.response.data.message);
      return;
    }

    setPopupMessage(response);
    setMovies(prev => prev.filter(movie => movie.id !== info.movie));
  } 

  return (
    <>
      <Header title='Suggestions' />
      <Container movies={movies} loading={loading} error={error} setModalInfo={setModalInfo} setModalRemoveInfo={setModalRemoveInfo} />
      <Lists modalInfo={modalInfo} setModalInfo={setModalInfo} />
      <Confirm showModal={modalRemoveInfo} setModal={setModalRemoveInfo} handler={removeMovie}>
        Are you sure you wish to remove this suggestion
        made by <strong>{modalRemoveInfo?.username || '.'}</strong>?
      </Confirm>
    </>
  );
}


function Container({ movies, loading, error, setModalInfo, setModalRemoveInfo }) {

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return (<MessageBox title='Error'>Something went wrong.</MessageBox>)
  }

  if (!movies.length) {
    return (
      <MessageBox title='Empty'>
        You have no suggestions.
        Tell your friends to suggest you some movies or series they like!
      </MessageBox>
    );
  }

  return (
    <motion.ul variants={container} initial={initial} animate={animate}>
      <AnimatePresence>
        {movies.map(movie => (
          <motion.li variants={item} exit={{ opacity: 0, x: 300, height: 0 }} key={movie.id + movie.username}>
            <SuggestionCard key={movie.id + movie.username}>
              <p>By {movie.username}</p>
              <MovieCard info={movie}>
                <Button title='Add to list' handler={() => setModalInfo({id: movie.id})} />
                <Button title='Remove' handler={() => setModalRemoveInfo({ username: movie.username, movie: movie.id })} type='danger' />
              </MovieCard>
            </SuggestionCard>
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  );
}
