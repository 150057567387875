import styled, {keyframes} from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerStyled = styled(motion.div)`
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  div {
    width: 25%;
    max-width: 100px;
    aspect-ratio: 1/1;
    border: 10px solid var(--clr-text-primary);
    border-radius: 100%;
    border-right-color: transparent;
    animation: ${rotate} 2s linear infinite;
  }
`;

function Spinner() {
  return (
    <AnimatePresence>
      <SpinnerStyled
        initial={{scale: 0}}
        animate={{scale: 1}}
        exit={{scale: 0}}
      >
        <div></div>
      </SpinnerStyled>
    </AnimatePresence>
  );
}

export default Spinner;