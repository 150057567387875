import styled from 'styled-components';

import { useState } from 'react';

import Button from 'atoms/Button';
import Backdrop from './components/Backdrop';
import Card from './components/Card';
import ButtonContainer from './components/ButtonContainer';

const CustomCard = styled(Card)`
  label {
    display: block;
    margin-bottom: 1rem;

    * {
      margin-top: 0.5rem;
    }
  }

  .input {
    position: relative;

    input {
      background: var(--clr-background-back);
      border: 0;
      padding: 0.5rem 1rem;
      font-family: var(--font-family);
      color: var(--clr-text-secondary);
      width: 100%;
      border-radius: 50px;
      font-size: 1rem;
    }
  }
`;

export default function AddFriend({ showModal, setShowModal, addFriend }) {

  const [value, setValue] = useState('');

  if (!showModal) {
    return null;
  }

  function handleSubmit(ev) {
    ev.preventDefault();

    if (!value || value.length > 20) {
      return;
    }

    addFriend(value);
  }

  function closeModal(ev) {
    setShowModal(false);
  }

  return (
    <Backdrop onClick={closeModal}>
      <CustomCard onClick={(ev) => ev.stopPropagation()}>
        <h2>Add friend</h2>
        <form onSubmit={handleSubmit}>
          <label>
            New friends's username
            <div className='input'>
              <input maxLength={20} value={value} placeholder={'Friend\'s username here...'} 
                id='username-input' type='text' name='username' 
                onChange={(ev) => setValue(ev.target.value)} required='required'
              />
            </div>
          </label>
          <ButtonContainer className='button-container'>
            <Button title='Send request' disabled={(!value || value.length < 4)} />
          </ButtonContainer>
        </form>
      </CustomCard>
    </Backdrop>
  );
}