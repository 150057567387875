import styled from 'styled-components';

const StyledMain = styled.main`
  padding: 2rem 1rem;
  padding-bottom: 7rem;
  background: var(--clr-background-back);
  min-height: 100vh;
  max-width: 450px;
  margin: auto;
  overflow-x: hidden;

  & > ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }
  
  @media (min-width: 600px) {
    padding: 3rem 3rem;
    max-width: 600px;
  }

  @media (min-width: 1020px) {
    padding: 3rem 3rem;
    max-width: 850px;

    & > ul {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
      margin-top: 1.5rem;

      article {
         height: 100%;
      }
    }
  }
`;

export default StyledMain;