import styled from 'styled-components';
import { faUser, faLock, faAt, faSignature } from '@fortawesome/free-solid-svg-icons';

import { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';

import CustomLink from 'atoms/CustomLink';
import Input from 'atoms/Input';
import Button from 'atoms/Button';

import { AuthContext } from 'utilities/AuthContext';
import { requests } from 'utilities/Api';

const StyledContainer = styled.main`
  display: grid;
  place-items: center;
  padding: 2rem 1rem;
  background: var(--clr-background-back);
  min-height: 100vh;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    color: var(--clr-text-primary);
    text-align: center;
    font-size: 3rem;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function Register() {
  const { authenticated, setPopupMessage } = useContext(AuthContext);
  
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('teste@teste.com.br');


  if (authenticated) {
    return (<Navigate to='/lists' />)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    
    if (!name.trim()) {
      setPopupMessage('You should input your name.');
      return;
    }

    if (!username.trim()) {
      setPopupMessage('Input a username for your account.')
    }

    if (!(password === confirmPassword)) {
      setPopupMessage('Passwords don\'t match.');
      return;
    }

    (async () => {
      let response;

      try {
        response = (await requests.post('register', {
          name, username, password, email
        })).data;
      } catch(err) {
        setPopupMessage(err.response.data.message);
        return;
      }

      setPopupMessage(response);
      navigate('/login');
    })();
  }


  return (
    <StyledContainer>
      <h1>MovieFliek</h1>
      <div>
        <form onSubmit={handleSubmit}>
          <Input 
            value={name} 
            setValue={setName} 
            placeholder='Your name' 
            id='name-input' 
            name='name' 
            icon={faSignature} 
            required={true}
            min={2}
            max={40}
            />
          <Input 
            value={username} 
            setValue={setUsername} 
            placeholder='Username' 
            id='username-input' 
            name='username' 
            icon={faUser} 
            required={true}
            min={4}
            max={20}
          />
          <Input 
            value={password} 
            setValue={setPassword} 
            placeholder='Password' 
            id='password-input'
            name='password' 
            icon={faLock} 
            required={true} 
            type='password'
            min={8}
          />
          <Input 
            value={confirmPassword} 
            setValue={setConfirmPassword} 
            placeholder='Confirm password' id='confirm-password-input'
            name='confirm-password' 
            icon={faLock} 
            required={true} 
            type='password'
            min={8}
          />
          <div style={{ display: 'none' }}>
            <Input 
              value={email} 
              setValue={setEmail} 
              placeholder='Email' 
              id='password-input'
              name='password' 
              icon={faAt} 
              required={true} 
              type='email' 
              disabled={true}
            />
          </div>
          <div className='actions-container'>
            <CustomLink to='/login'>
              Login
            </CustomLink>
            <Button title='Register' />
          </div>
        </form>   
      </div>
    </StyledContainer>
  )
}
