

export const opacity = {
  initial: 'hidden',
  animate: 'show',

  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  },
  
  item: {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
}


export const modal = {
  backdrop: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit:    { opacity: 0 }
  },

  card: {
    initial: { y: '-150%' },
    animate: { y: 0 },
    exit:    { y: '150%' }
  }
}