import styled from 'styled-components';

import { motion } from 'framer-motion';

import { opacity } from 'styles/MotionVariants';

const StyledContainer = styled(motion.section)`
  background: var(--clr-background-front);
  padding: 2rem;
  border-radius: 10px;
  margin-top: ${({noMargin}) => (noMargin) ? '0' : '3rem'};
  text-align: center;
  
  h2 {
    color: var(--clr-text-primary);
    letter-spacing: 1px;
    margin-bottom: 2rem;
  }

  p {
    color: var(--clr-text-secondary);
    font-size: 1rem;
  }

  a {
    color: #fbf849;
    position: relative;

    &::after {
      content: '';
      background: #fbf849;
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      transition: height 100ms, bottom 100ms;
    }

    &:hover::after {
      height: 7px;
      bottom: -6px;
    }
  }
`;

const { container, item, animate, initial } = opacity;

export default function MessageBox({title, type = 'primary', noMargin = false, children}) {
  return (
    <StyledContainer
      noMargin={noMargin}
      className={type}
      variants={container}
      initial={initial}
      animate={animate}
    >
      {title && (
        <motion.h2 variants={item}>
          {title}
        </motion.h2>
      )}
      <motion.p variants={item}>
        {children}
      </motion.p>
    </StyledContainer>
  );
}