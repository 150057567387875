import axios from 'axios';

export const requests = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export const delay = (time) => new Promise(res => setTimeout(res, time));
