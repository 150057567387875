import styled from 'styled-components';

const StyledArticle = styled.article`
  padding: 1rem;
  background: var(--clr-background-front);
  border-radius: 10px;
  color: var(--clr-text-primary);
  display: flex;
  flex-direction: column;

  p {
    color: var(--clr-text-secondary);
    font-size: 1rem;
  }
  
  .container-movie {
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;
    width: 100%;
    
    img {
      aspect-ratio: 1/1.5;
      width: 40%;
      border-radius: 5px;
      flex-shrink: 0;
    }

    .info {
      flex: 1;

      h2 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }

      p {
        margin-top: 0.5rem;
      }
    }
  }

  .movie-plot {
    margin: 1rem 0;
    flex: 1;
  }

  .container-button {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;

    * {
      /* flex: 1; */
      
      * {
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) {
    padding: 1.5rem;
    border-radius: 15px;

    p {
      font-size: 1.15rem;
    }

    .container-movie {
      .info {
          h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }

          p {
            font-size: 1.15rem;
            margin-top: 0.75rem;
          }
      }
    }
  }
`;

export default function MovieCard({children, info}) {
  return (
    <StyledArticle key={info.id}>
      <div className='container-movie'>
        <img src={info.poster} alt={info.title + 'poster.'} />
        <div className='info'>
          <h2>{info.title}</h2>
          <p><strong>Year:</strong> {info.year}</p>
          <p><strong>Rating:</strong> {info.rating}</p>
          <p><strong>Duration:</strong> {info.duration}</p>
        </div>
      </div>
      <p className='movie-plot'>{info.plot}</p>
      <div className='container-button'>
        {children}
      </div>
    </StyledArticle>
  );
}