import styled from 'styled-components';

import { useContext } from 'react';
import { motion } from 'framer-motion';

import Avatar from 'atoms/Avatar';

import { AuthContext } from 'utilities/AuthContext';

const StyledContainer = styled(motion.article)`
  border-radius: 10px;
  background: var(--clr-background-front);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: var(--clr-text-primary);
  }

  .username {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: var(--clr-text-secondary);
  }

  & > div {
    display: flex;
    gap: 2rem;
  }
`;

export default function UserInfo(props) {

  const { user: { name, username, avatar }} = useContext(AuthContext);

  return (
    <StyledContainer {...props}>
      <Avatar avatar={avatar} size='medium' />
      <h2 className='title'>{name}</h2>
      <p className='username'>@{username}</p>
    </StyledContainer>
  );
}