import styled from 'styled-components';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

import { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from 'utilities/AuthContext';

import CustomLink from 'atoms/CustomLink';
import Input from 'atoms/Input';
import Button from 'atoms/Button';

const StyledContainer = styled.main`
  display: grid;
  place-items: center;
  padding: 2rem 1rem;
  background: var(--clr-background-back);
  min-height: 100vh;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    color: var(--clr-text-primary);
    text-align: center;
    font-size: 3rem;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function Login() {
  const { authenticated, login } = useContext(AuthContext);
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  if (authenticated) {
    return (<Navigate to='/lists' />)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    login(username, password);
  }

  return (
    <StyledContainer>
      <h1>MovieFliek</h1>
      <div>
        <form onSubmit={handleSubmit}>
          <Input 
            value={username} 
            setValue={setUsername} 
            placeholder='Username' 
            id='username-input' 
            name='username' 
            icon={faUser} 
            required={true}
            min={4}
            max={20}
          />
          <Input 
            value={password} 
            setValue={setPassword} 
            placeholder='Password' 
            id='password-input'
            name='password' 
            icon={faLock} 
            required={true} 
            type='password'
          />
          <div className='actions-container'>
            <CustomLink to='/register'>
              Register
            </CustomLink>
            <Button title='Login' />
          </div>
        </form>   
      </div>
    </StyledContainer>
  )
}