import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from './routes/Login';
import Register from './routes/Register';
import App from './App';
import List from './routes/List';
import Lists from './routes/Lists';
import Watched from './routes/Watched';
import Search from './routes/Search';
import Suggestions from './routes/Suggestions';
import Account from './routes/Account';
import Friends from './routes/Friends';
import Settings from './routes/Settings';
import ChangeAvatar from './routes/ChangeAvatar';
import ChangePassword from './routes/ChangePassword';

import PrivateRoute from './utilities/PrivateRoute';

import AuthProvider from './utilities/AuthContext';


export default function AppRoutes() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/' element={<PrivateRoute><App /></PrivateRoute>}>
            <Route path='search' element={<Search />}/>
            <Route path='watched' element={<Watched />}/>
            <Route path='lists/:id' element={<List />} />
            <Route path='lists' element={<Lists />} />
            <Route path='account/settings/password' element={<ChangePassword />} />
            <Route path='account/settings/avatar' element={<ChangeAvatar />} />
            <Route path='account/settings' element={<Settings />} />
            <Route path='account/friends' element={<Friends />} />
            <Route path='account' element={<Account />} />
            <Route path='suggestions' element={<Suggestions />} />
            <Route path='*' element={<h1>404 - not found</h1>} />
            <Route path='' element={<Navigate to='lists' />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
} 