import styled from 'styled-components';

const StyledSection = styled.article`
  padding: 1rem;
  border-radius: 10px;
  background: var(--clr-background-front);

  .title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: var(--clr-text-primary);
  }

  .quantity {
    font-size: 1rem;
    color: var(--clr-text-secondary);
  }

  @media (min-width: 600px) {
    padding: 1.5rem;
    border-radius: 15px;

    .title {
      font-size: 1.5rem;
    }

    .quantity {
      font-size: 1.25rem;
    }
  }

`;

export default function ListCard({title, size}) {
  return (
    <StyledSection>
      <h2 className='title'>{title}</h2>
      <p className='quantity'>{size} title(s)</p>
    </StyledSection>
  );
}