import styled from 'styled-components';

import { useState, useContext } from 'react';

import Button from 'atoms/Button';
import BaseModal from './BaseModal';
import ButtonContainer from 'components/modals/components/ButtonContainer';

import { requests } from 'utilities/Api';
import { AuthContext } from 'utilities/AuthContext';

const StyledForm = styled.form`
  label {
    display: block;
    margin-bottom: 1rem;

    * {
      margin-top: 0.5rem;
    }
  }
  
  input, textarea {
    background: var(--clr-background-back);
    border: 0;
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    color: var(--clr-text-secondary);
    width: 100%;
    border-radius: 10px;
  }

  .input {
    position: relative;

    input {
      border-radius: 50px;
      font-size: 1rem;
    }
  }

  .text-area {
    position: relative;
    margin: 0;
    
    textarea {
      resize: none;
    }

  }

  span {
    position: absolute;
    right: -0.5rem;
    top: -0.7rem;
    font-size: 0.75rem;
    background: var(--clr-background-secondary);
    padding: 0.25rem;
    border-radius: 10px;
  }
`;


export default function AddList({ updateLists, showModal, setModal }) {
  const { setPopupMessage } = useContext(AuthContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  function createList(ev) {
    ev.preventDefault();
    
    (async () => {
      let response;
      
      try {
        response = await requests.post('playlists', {
          name: name,
          description: description
        });
      } catch (err) {
        setPopupMessage(err.response.data);
        return;
      }
      
      setPopupMessage(response.data);
      setModal(false);
      updateLists();
    })();
  }

  return (
    <BaseModal showModal={showModal} setModal={setModal}>
      <h2>Create new list</h2>
      <StyledForm onSubmit={createList}>
        <label>
          New list's name
          <div className='input'>
            <span>{name.length}/25</span>
            <input maxLength={25} value={name} placeholder='Input a name for your list...' 
              id='list-name-input' type='text' name='list-name' 
              onChange={(ev) => setName(ev.target.value)} required='required'
            />
          </div>
        </label>
        <label>
          New list's description (Optional)
          <div className='text-area'>
            <span>{description.length}/70</span>
            <textarea value={description} onChange={(ev) => setDescription(ev.target.value)} 
              placeholder='Type a brief description about the movies in this new list!' 
              rows='3' maxLength={70}
            />
          </div>
        </label>
        <ButtonContainer>
          <Button title='Create list' disabled={!name.trim() ? true : false} />
        </ButtonContainer>
      </StyledForm>
    </BaseModal>
  );
}
