import styled from 'styled-components';

import Button from 'atoms/Button';
import BaseModal from './BaseModal';
import ButtonContainer from './components/ButtonContainer';


const StyledContainer = styled.div`
  p {
    margin: 2rem;
    text-align: center;
  }

  div {
    justify-content: space-between;

    button {
      flex-basis: 45%;
    }
  }
`;

export default function Confirm({ showModal, setModal, handler, children }) {
  function handleClick(ev) {
    handler(showModal);
    setModal(null);
  }

  return (
    <BaseModal showModal={showModal} setModal={setModal}>
      <h2 style={{ textAlign: 'center' }}>Confirm</h2>
      <StyledContainer>
        <p>
          {children}
        </p>
        <ButtonContainer>
          <Button title='No' type='secondary' handler={() => setModal(null)} />
          <Button title='Yes' type='danger' handler={handleClick}/>
        </ButtonContainer>
      </StyledContainer>
    </BaseModal>
  );
} 