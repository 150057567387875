import styled from 'styled-components';

import { createContext, useState, useEffect, useContext } from 'react';
import { requests } from './Api';

import Spinner from '../atoms/Spinner';
import PopupMessage from '../components/PopupMessage';

export const AuthContext = createContext();

export const useGlobalContext = () => useContext(AuthContext);

const SpinnerContainer = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
`;

export default function AuthProvider({children}) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    (async () => {
      let response;

      try {
        response = (await requests.get('authenticated')).data;
      } catch(err) {
        console.log(err);
        setLoading(false);
        setError(true);
        return;
      }
      
      if (response.authenticated === true) {
        setUser(response.user);
      }

      setLoading(false);
    })();
  }, []);

  function login(username, password) {
    setPopupMessage('');
    (async () => {
      let response;
      try {
        response = (await requests.post('login', {
          username, password
        })).data;
      } catch(e) {
        console.log(e);
        setPopupMessage('Something went wrong.');
        return;
      }

      if (typeof response == 'string') {
        setPopupMessage(response);
        return;
      }
      setUser(response);
    })();
  }

  function logout() {
    (async () => {
      let response;

      try {
        response = (await requests('logout')).data;
      } catch(err) {
        console.log('error: ', err);
        setUser(null);
        return;
      }
      
      setPopupMessage(response);
      setUser(null);
    })();
  }

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, login, logout, setPopupMessage, setUser }} >
      {(loading)
        ? <SpinnerContainer><Spinner /></SpinnerContainer>
        : (error)
            ? (<ErrorContainer />)
            : (<>
                {children}
                <PopupMessage message={popupMessage} setMessage={setPopupMessage} authenticated={!!user}/>
              </>)  
      }
    </AuthContext.Provider>
  )
}


const StyledContainer = styled.main`
  height: 100vh;
  display: grid;
  place-items: center;

  h1 {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    color: var(--clr-text-secondary);
    text-align: center;
    font-size: 3rem;
  }

  p {
    color: var(--clr-text-primary);
    text-align: center;
    font-size: 1.25rem;
  }

  button {
    margin-right: 0.25rem;
    font-size: 1.25rem;
    background: none;
    color: #fbf849;
    /* text-decoration: underline; */
    border: 0;
    position: relative;

    &::after {
      content: '';
      background: #fbf849;
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      transition: height 100ms, bottom 100ms;
    }

    &:hover::after {
      height: 7px;
      bottom: -6px;
    }
  }
`;


function ErrorContainer() {
  return (
    <StyledContainer>
      <div>
        <h1>MovieFliek</h1>
        <p>
          Something went wrong.
          <br />
          <button onClick={() => window.location.reload()}>Click here</button>
          to reload
          <br />
          or try again later.
        </p>
      </div>
    </StyledContainer>
  );
}
