import styled from 'styled-components';

import Avatars64 from '../assets/64.png';
import Avatars128 from '../assets/128.png';
import Avatars256 from '../assets/256.png';

const avatars = {
  small: Avatars64,
  medium: Avatars128,
  big: Avatars256
};

const sizes = {
  small: 50,
  medium: 128,
  big: 200
};

const StyledContainer = styled.div`
  position: relative;
  border-radius: 100%;
  background:  var(--clr-background-secondary);
  background-size: cover;
  width: ${({size}) => sizes[size]}px;
  height: ${({size}) => sizes[size]}px;
  /* background-image: url(${({size}) => avatars[size]}); */
  background-image: url(${Avatars256});
  background-position: ${({avatar, size}) => (-avatar + 1)  * sizes[size]}px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: -5px -5px -5px -5px;
    border: 7px solid var(--clr-background-back);
    border-radius: 100%;
  }
`;

export default function Avatar({ avatar = 1, size = 'medium' }) {
  return (
    <StyledContainer className={`avatar`} avatar={avatar} size={size} />
  );
}