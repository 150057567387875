import styled from 'styled-components';
import { opacity } from 'styles/MotionVariants';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header';
import Button from 'atoms/Button';
import ListCard from 'components/ListCard';
import MessageBox from 'components/MessageBox';
import Spinner from 'atoms/Spinner';
import AddList from 'components/modals/AddList';

import { requests } from 'utilities/Api';

export default function Lists() {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    updateLists();
  }, []);

  async function updateLists() {
    let response;

    try {
      response = (await requests.get('playlists')).data;
    } catch (err) {
      console.log(err);
      setError(true);
      setLoading(false);
      return;
    }

    setLists(response);
    setLoading(false);
  }

  return (
    <>
      <Header title='Lists' 
        rightButton={<Button title='Create new list' handler={() => setModal(true)} />}
      />
      <Container error={error} loading={loading} lists={lists} />
      {/* <Button title='Add Message' handler={() => setPopupMessage('Yo test message')} /> */}
      <AddList updateLists={updateLists} setLoading={setLoading} showModal={modal} setModal={setModal} setError={setError} />
    </>
  );
}

const StyledUl = styled(motion.ul)`
  li {
    border-radius: 10px;
  }
`;

function Container({error, loading, lists}) {
  if (loading) {
    return (<Spinner />)
  }

  if (error) {
    return (<MessageBox title='Error' type='error'>Something went wrong, please try again later.</MessageBox>);
  }
  
  if (!lists.length) {
    return (
      <MessageBox title='Empty'>
        You have no lists. Try creating a new one by clicking on the "Create new list" button!
      </MessageBox>
    );
  }

  return (
    <StyledUl
    variants={opacity.container}
      initial={opacity.initial}
      animate={opacity.animate}
    >
      {lists.map(list => (
        <motion.li
          variants={opacity.item}
          whileHover={{ scale: 1.1, boxShadow: '0 5px 5px 5px #11111177' }}
          whileTap={{ scale: 0.95}}
          key={list.id}
        >
          <Link to={`/lists/${list.id}`}>
            <ListCard title={list.name} size={list.size} />
          </Link>
        </motion.li>
      ))}
    </StyledUl>
  );
}