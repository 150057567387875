import { useEffect, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Header from 'components/Header';
import MovieCard from 'components/MovieCard';
import Button from 'atoms/Button';

import { requests } from 'utilities/Api';
import { AuthContext } from 'utilities/AuthContext';
import Spinner from 'atoms/Spinner';
import MessageBox from 'components/MessageBox';
import SuggestMovie from 'components/modals/SuggestMovie';
import Confirm from 'components/modals/Confirm';

import { opacity } from 'styles/MotionVariants';

export default function Watched() {
  const { setPopupMessage } = useContext(AuthContext);

  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showConfirm, setShowConfirm] = useState(null);

  useEffect(() => {
    updateMovies();
  }, []);

  async function updateMovies() {
    let response;

    try {
      response = (await requests.get('watched')).data;
    } catch(err) {
      console.log('error:', err);
      setLoading(false);
      return;
    }

    setLoading(false);
    setMovies(response);
  }

  async function removeWatched(info) {
    let response;

    try {
      response = (await requests.delete(`watched?movie=${info.id}`)).data;
    } catch(err) {
      console.log('error:', err);
      return;
    }

    setPopupMessage(response);
    setMovies(prev => prev.filter(movie => movie.id !== info.id));
  }

  return (
    <>
      <Header title='Watched' />
      {loading
        ? <Spinner />
        : !movies.length
            ? <MessageBox title='Empty'>You have no saved watched movies.</MessageBox>
            : (
                <motion.ul
                  variants={opacity.container}
                  initial={opacity.initial}
                  animate={opacity.animate}
                >
                  <AnimatePresence>
                    {movies.map(movie => (
                      <motion.li
                        key={movie.id}
                        variants={opacity.item}
                        exit={{ opacity: 0, x: 300, height: 0 }}
                      >
                        <MovieCard info={movie}>
                          <Button title='Suggest' handler={() => setShowModal({id: movie.id})} />
                          <Button value={movie.id} title='Remove' handler={(ev) => setShowConfirm({id: ev.currentTarget.value})} type='danger' />
                        </MovieCard>
                      </motion.li>
                    ))}
                  </AnimatePresence>
                </motion.ul>
      )}
      <SuggestMovie showModal={showModal} setShowModal={setShowModal} />
      <Confirm showModal={showConfirm} setModal={setShowConfirm} handler={removeWatched}>
        Are you sure you wish to remove this movie from your watched titles?
      </Confirm>
    </>
  );
}
