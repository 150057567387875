import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const StyledSpan = styled(motion.div)`
  color: #fbf849;
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    background: #fbf849;
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    transition: height 100ms, bottom 100ms;
  }

  &:hover::after {
    height: 7px;
    bottom: -6px;
  }
`;

export default function CustomLink({children, ...props}) {
  return (
    <Link {...props}>
      <StyledSpan
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {children}
      </StyledSpan>
    </Link>
  );
}