import styled from 'styled-components';

import { useState, useEffect, useContext } from 'react';

import Button from 'atoms/Button';
import Spinner from 'atoms/Spinner';
import FriendCard from 'components/FriendCard';
import BaseModal from './BaseModal';
import ButtonContainer from './components/ButtonContainer';

import { AuthContext } from 'utilities/AuthContext';

import { requests } from 'utilities/Api';

const StyledList = styled.ul`
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  display: grid;
  gap: 1rem;
  padding: 5px;
  margin-bottom: 1rem;
    
  li {
    position: relative;
  }

  li.selected::after {
    content: '';
    position: absolute;
    display: block;
    inset: -5px -5px -5px -5px;
    border: 3px solid white;
    z-index: 1;
    border-radius: 200px;
  }
`;

export default function SuggestMovie({ showModal, setShowModal }) {

  const { setPopupMessage } = useContext(AuthContext);

  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  
  useEffect(() => {
    (async () => {
      let response;

      try {
        response = (await requests.get('friends')).data.friends;
      } catch (err) {
        console.log(err.response);
        return;
      }

      setLoading(false);
      setFriends(response);
    })();
  }, []);

  useEffect(() => {
    if (!showModal) {
      setSelected(null);
    }
  }, [showModal]);
  
  async function handleSubmit(ev) {
    ev.stopPropagation();

    let response;

    try {
      response = (await requests.post('suggestions', {movie: showModal.id, username: selected})).data;
    } catch (err) {
      setPopupMessage(err.response.data);
      return;
    }

    setPopupMessage(response);
    setSelected(null);
    setShowModal(null);
 
  }

  return (
    <BaseModal showModal={showModal} setModal={setShowModal}>
      <h2>Add friend</h2>
      {loading && <Spinner />}
      {friends.length && (
        <StyledList>
          {friends.map(friend => (
            <li className={selected === friend.username ? 'selected' : undefined} key={friend.username} onClick={() => setSelected(friend.username)}>
              <FriendCard name={friend.name} username={friend.username} avatar={friend.avatar} />
            </li>
          ))}
        </StyledList>
      )}
      <ButtonContainer>
        <Button disabled={!selected} title='Suggest' handler={handleSubmit} />
      </ButtonContainer>
    </BaseModal>
  );
}