import styled from 'styled-components';

import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faFilm, faSearch, faUser, faStream, faHistory } from '@fortawesome/free-solid-svg-icons';

import { Link, useLocation } from 'react-router-dom';

const StyledNav = styled.nav`
  background: var(--clr-background-back);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-shadow: 0 15px 20px white;
  display: grid;
  place-items: center;
  z-index: 1;
  
  ul {
    max-width: 400px;
    margin: auto;
    display: grid;
    list-style: none;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    li {
      font-size: 0.75rem;
      text-align: center;
      transition: background-color 150ms;
      border-top: 5px solid transparent;
      transition: border-color 100ms;

      &:hover {
        border-color: #bbb;
      }

      &.selected {
        border-color: #eee;
      }
    }
    div {

      // background: #555;
      margin: auto;
      border-radius: 100%;
      display: block;
    }

    .icons {
      font-size: 2rem;
    }
    
    a {
      text-decoration: none;
      color: white;
      display: block;
      padding: 0.5rem 0;
    }
  }

  @media (min-width: 600px) {
    bottom: initial;
    top: 0;
    width: auto;
    height: 100vh;
    box-shadow: -15px 0 20px white;

    ul {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

      li {
        border: 0;
        border-right: 5px solid transparent;
        font-size: 1rem;
      }
      
      a {
        padding: 1rem;
      }
    }
  } 
`;


export default function Nav({ path }) {

  const { pathname } = useLocation();

  return (
    <StyledNav>
      <ul>
        <li className={pathname.includes('lists') ? 'selected' : undefined}>
          <NavItem key='MovieFliek' title='MovieFliek' url='/lists'>
            <FontAwesomeIcon key='1' icon={faStream} color='#bbb' transform='shrink-4' />
          </NavItem>
        </li>
        <li className={pathname.includes('search') ? 'selected' : undefined}>
          <NavItem key='Search' title='Search' url='/search'>
            <FontAwesomeIcon key='1' icon={faSearch} color='#bbb' transform='shrink-4' />
          </NavItem>
        </li>
        <li className={pathname.includes('watched') ? 'selected' : undefined}>
          <NavItem key='Watched' title='Watched' url='/watched'>
            <FontAwesomeIcon key='1' icon={faFilm} transform='shrink-4' color='#777' />
            <FontAwesomeIcon key='2' icon={faHistory} color='#bbb' transform='shrink-5 left-7 up-4' />
          </NavItem>
        </li>
        <li className={pathname.includes('suggestions') ? 'selected' : undefined}>
          <NavItem key='Suggestions' title='Suggestions' url='/suggestions'>
            <FontAwesomeIcon key='1' icon={faFilm} color='#777' transform='shrink-4 right-2' />
            <FontAwesomeIcon key='2' icon={faExclamation} color='#bbb' transform='shrink-5 up-3 left-5' />
          </NavItem>
        </li>
        <li className={pathname.includes('account') ? 'selected' : undefined}>
          <NavItem key='Account' title='Account' url='/account'>
            <FontAwesomeIcon key='1' icon={faUser} color='#bbb' transform='shrink-3' />
          </NavItem>
        </li>
      </ul>
    </StyledNav>
  );
}


function NavItem({title, icons, url, children}){
  return (
    <motion.div
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
    >
      <Link to={url}>
        <div className='icons fa-layers fa-fw'>
          {children}
        </div>
        {title}
      </Link>
    </motion.div>
  );
}