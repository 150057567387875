import styled from 'styled-components';

import { useState, useEffect, useContext } from 'react';

import Button from 'atoms/Button';

import { requests } from 'utilities/Api';
import { AuthContext } from 'utilities/AuthContext';

const StyledContainer = styled.div`
  background: #222629aa;
  position: fixed;
  inset: 0 0 0 0;
  display: grid;
  place-items: center;
  z-index: 1;
  
  &.hide {
    display: none;
  }

  .card {
    width: 300px;
    background: var(--clr-background-front);
    color: var(--clr-text-primary);
    padding: 1rem 1rem;
    border-radius: 10px;
  }
  
  h2 {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    margin-bottom: 1rem;

    * {
      margin-top: 0.5rem;
    }
  }
  
  input, textarea {
    background: var(--clr-background-back);
    border: 0;
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    color: var(--clr-text-secondary);
    width: 100%;
    border-radius: 10px;
  }

  .input {
    position: relative;

    input {
      border-radius: 50px;
      font-size: 1rem;
    }
  }

  .text-area {
    position: relative;
    margin: 0;

    
    textarea {
      resize: none;
    }

  }

  span {
    position: absolute;
    right: -0.5rem;
    top: -0.7rem;
    font-size: 0.75rem;
    background: var(--clr-background-secondary);
    padding: 0.25rem;
    border-radius: 10px;
  }
`;

export default function EditList({ listId, listInfo, updateList, showModal, setModal }) {
  const { setPopupMessage } = useContext(AuthContext);

  const [name, setName] = useState(listInfo.name);
  const [description, setDescription] = useState(listInfo.description);

  useEffect(() => {
    setName(listInfo.name);
    setDescription(listInfo.description);
  }, [listInfo]);

  function handleSubmit(ev) {
    ev.preventDefault();
    
    (async () => {
      let response;
      
      try {
        response = await requests.patch(`playlists/${listId}`, {
          name: name,
          description: description
        }).data;
      } catch (err) {
        setPopupMessage(err.response.data);
        return;
      }
      
      setPopupMessage(response);
      setModal(null);
      updateList();
    })();
  }

  function hideModal(ev) {
    ev.stopPropagation();

    if (ev.target === ev.currentTarget) {
      setModal(false);
    }
  }

  return (
    <StyledContainer className={showModal ? '' : 'hide'} onClick={hideModal}>
      <div className='card'>
        <h2>Update</h2>
        <form onSubmit={handleSubmit}>
          <label>
            New list's name
            <div className='input'>
              <span>{name.length}/25</span>
              <input maxLength={25} value={name} placeholder='Input a name for your list...' 
                id='list-name-input' type='text' name='list-name' 
                onChange={(ev) => setName(ev.target.value)} required='required'
              />
            </div>
          </label>
          <label>
            New list's description (Optional)
            <div className='text-area'>
              <span>{description.length}/70</span>
              <textarea value={description} onChange={(ev) => setDescription(ev.target.value)} 
                placeholder='Type a brief description about the movies in this new list!' 
                rows='3' maxLength={70}
              ></textarea>

            </div>
          </label>
          <Button title='Save' />
        </form>
      </div>
    </StyledContainer>
  );
}
