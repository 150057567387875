import styled from 'styled-components';
import { opacity } from 'styles/MotionVariants';

import StyledTitle from '../atoms/StyledTitle';

const StyledHeader = styled.header`
  position: relative;

  .container {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    gap: 0.5rem;
  }

  .title {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

`;

export default function Header({children, title, leftButton, rightButton, leftHandler, rightHandler}) {
  return (
    <StyledHeader>
      <div className='container'>
        {leftButton}
        <StyledTitle
          className='title'
          variants={opacity.item}
          initial={opacity.initial}
          animate={opacity.animate}
        >
          {title}
        </StyledTitle>
        {rightButton}
      </div>
      {children}
    </StyledHeader>
  );
}