import styled from 'styled-components';

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCog } from '@fortawesome/free-solid-svg-icons';

import Header from 'components/Header';
import Button from 'atoms/Button';
import UserInfo from 'components/UserInfo';

import { AuthContext } from 'utilities/AuthContext';
import { opacity } from 'styles/MotionVariants';

const StyledContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  
  a {
    flex-basis: 45%;
  }
`;

const StyledCard = styled(motion.div)`
  border-radius: 10px;
  padding: 1rem;
  background-color: var(--clr-background-front);
  
  .icon {
    width: max-content;
    margin: auto;
  }

  p {
    text-align: center;
    font-size: 1rem;
    color: var(--clr-text-primary);
    letter-spacing: 0.5px;
    margin-top: 1rem;
  }
  
`;

const cardTransitions = {
  whileHover: { scale: 1.1 },
  whileTap: { scale: 0.9 }
};

export default function Account() {
  const { logout } = useContext(AuthContext); 

  return (
    <>
      <Header title='Account' rightButton={<Button title='Log out' type='danger' handler={logout} />} />
      <motion.div
        variants={opacity.container}
        initial={opacity.initial}
        animate={opacity.animate}
      >
        <UserInfo name='Wellington N.' username='admin_do_grupo' variants={opacity.items}/>
        <StyledContainer variants={opacity.item}>
          <Link to='friends'>
            <StyledCard
              whileHover={cardTransitions.whileHover}
              whileTap={cardTransitions.whileTap}
            >
              <div className='icon'>
                <FontAwesomeIcon icon={faUserFriends} color='#bbb' size='4x' />
              </div>
              <p>Friends</p>
            </StyledCard>
          </Link>
          <Link to='settings'>
            <StyledCard
              whileHover={cardTransitions.whileHover}
              whileTap={cardTransitions.whileTap}
            >
              <div className='icon'>
                <FontAwesomeIcon icon={faCog} color='#bbb' size='4x' />
              </div>
              <p>Settings</p>
            </StyledCard>
          </Link>
        </StyledContainer>
      </motion.div>
    </>
  );
}
