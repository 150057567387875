import styled from 'styled-components';

import { motion } from 'framer-motion';

const StyledCard = styled(motion.div)`
  position: absolute;
  top: 2rem;
  right: 0;
  width: 10rem;
  padding: 1rem;
  border-radius: 10px;
  background: var(--clr-background-secondary);

  ul {
    list-style: none;
    display: grid;
    gap: 0.5rem;

    li {

      button {
        background: inherit;
        border: none;
        font-family: var(--font-family);
        color: var(--clr-text-primary);
        width: 100%;
        padding: 0.5rem;
        text-align: left;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          background: #00000022;
          border-radius: 5px;
        }
      }
    }
  }
`;

export default function DropdownMenu({ children }) {

  return (
    <StyledCard
      style={{ originX: 1, originY:0  }}
      initial={{ scale: 0}}
      animate={{scale: 1}}
      exit={{scale: 0}}
    >
      <ul>
        {children}
      </ul>
    </StyledCard>
  );
}

export function DropdownItem({ icon, title, handler }) {
  return (
    <li>
      {icon} 
      <button onClick={handler}>{title}</button>
    </li>
  );
}