import { useEffect, useRef, useState, useContext } from 'react';

import Button from 'atoms/Button';
import RadioButton from 'atoms/RadioButton';
import Spinner from 'atoms/Spinner';
import BaseModal from './BaseModal';
import ButtonContainer from './components/ButtonContainer';
import MessageBox from 'components/MessageBox';

import { requests } from 'utilities/Api';
import { AuthContext } from 'utilities/AuthContext';


export default function Lists({modalInfo, setModalInfo}) {
  const { setPopupMessage } = useContext(AuthContext);

  const formElem = useRef(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  // show which option is selected
  useEffect(() => {
    formElem.current?.querySelectorAll('[type="radio"]')
      .forEach(radio => {
        if (Number(radio.value) === selectedOption) {
          radio.parentElement.classList.add('selected');
        } else {
          radio.parentElement.classList.remove('selected');
        }
      });
  });
    
  useEffect(() => {  
    if (!modalInfo) {
      setSelectedOption('');
    }
  }, [modalInfo]);

  useEffect(() => {
    (async () => {
      let response;

      try {
        response = (await requests.get('playlists')).data;
      } catch(err) {
        setLoading(false);
        console.log('error:', err.response);
        return;
      }

      setLoading(false);
      setLists(response);
    })();
  }, []);

  function handleSubmit(ev) {
    ev.preventDefault();

    if (!selectedOption) {
      return;
    }

    (async () => {
      let response;
      
      try {
        response = (await requests.post(`playlists/${selectedOption}`, { movie: modalInfo.id })).data;
      } catch (err) {
        setPopupMessage(err.response.data);
        return;
      }
      
      setPopupMessage(response);
      setModalInfo(null);
      setSelectedOption('');
    })();
  }

  return (
    <BaseModal setModal={setModalInfo} showModal={modalInfo} >
      {loading && (<h2>Choose the desired list: </h2>)}
      {loading && (<Spinner />)}
      
        {(!lists.length && !loading) 
          ? (<MessageBox noMargin={true} title='Empty'>You have no lists, go to the main page and create a new one!</MessageBox>)
          : (
            <>
              <h2>Choose the desired list: </h2>
              <form ref={formElem}> 
                {lists.map(list => (
                  <RadioButton key={list.id} name='list-id' value={list.id} label={list.name} handleClick={setSelectedOption} />
                ))}
                <ButtonContainer>
                  <Button title='Add' handler={handleSubmit} disabled={!lists.length ? true : false}/>
                </ButtonContainer>
              </form>
            </>
        )}

    </BaseModal>
  );
}
