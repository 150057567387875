import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Header from 'components/Header';
import IconButton from 'atoms/IconButton';
import Input from 'atoms/Input';
import Button from 'atoms/Button';

import { useGlobalContext } from 'utilities/AuthContext';

const StyledContainer = styled.article`
  form {
    max-width: 40ch;
    margin: auto;
    margin-top: 3rem;

    label {
      color: var(--clr-text-primary);
      font-family: var(--font-family);
      margin-bottom: 1rem;
      display: block;
      text-indent: 1.5rem;

      & > div {
        margin-top: 0.25rem;
      }
    }

    .button-container {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 2rem;
    }
  }
`;

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { setPopupMessage } = useGlobalContext();

  function handleSubmit(ev) {
    ev.preventDefault();
    setPopupMessage('This feature is not ready yet. I\'m sorry.');
  }

  return (
    <StyledContainer>
      <Header title='Change password'
        leftButton={(<Link to='/account/settings'><IconButton icon={<FontAwesomeIcon icon={faArrowLeft} color='#fff' transform='grow-10' />} /></Link>)}
      />
      <form onSubmit={handleSubmit}>
        <label>
          Current password
          <Input value={currentPassword} setValue={setCurrentPassword} 
            placeholder='Type your current password'
            type='password'
            required
          />
        </label>
        <label>
          New password
          <Input value={newPassword} setValue={setNewPassword} 
            placeholder='Type your new desired password'
            type='password'
            required
          />
        </label>
        <label>
          Confirm new password
          <Input value={confirmPassword} setValue={setConfirmPassword} 
            placeholder='Type your new password again'
            type='password'
            required
          />
        </label>
        <div className='button-container'>
          <Button title='Save' />
        </div>
      </form>
    </StyledContainer>
  );
}