import styled from 'styled-components';

import { Outlet } from 'react-router-dom';

import StyledMain from './atoms/MainContainer';
import Nav from './components/Nav';

const StyledContainer = styled.div`
  min-height: 100vh;

  @media (min-width: 600px) {
    padding-left: 125px;
  } 
`;

function App() {
  return (
    <StyledContainer>
      <Nav path={window.location.pathname} />
      <StyledMain>
        <Outlet />
      </StyledMain>
    </StyledContainer>
  );
}

export default App;
