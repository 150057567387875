import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { motion } from 'framer-motion';

const InputContainer = styled(motion.div)`
  padding: 0.5rem 1rem;
  display: flex;
  background: var(--clr-background-front);
  border-radius: 50px;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
    transition: width 200ms;
  }

  &:hover::after {
    width: 34%;
  }

  &:focus-within::after {
    width: 100%;
  }

  &.disabled input {
    color: #777;
  }

  input {
    margin: 0 0.5rem;
    flex: 1;
    background: none;
    border: 0;
    color: var(--clr-text-primary);
    outline: 0;
    font-size: 1rem;
  }
  
  button {
    border: 0;
    background: none;
  }

  @media (min-width: 600px) {
    input {
      font-size: 1.15rem;
    }
  }
`;

export default function Input({ icon, value, setValue, ...rest }) {
  return (
    <InputContainer
      className={rest.disabled ? 'disabled' : null}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
    >
      {icon && (<FontAwesomeIcon icon={icon} color={rest.disabled ? '#777' : '#ccc'} />)}
      <input
        value={value} 
        onChange={(ev) => setValue(ev.target.value)}
        {...rest}
      />
    </InputContainer>
  );
}