import styled from 'styled-components';

const StyledLabel = styled.label`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  .radio {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 40px;
    border: 2px solid white;
    margin-right: 0.5rem;
    padding: 3px;
  }

  &.selected .radio div {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: white;
  }

  @media (min-width: 600px) {
    font-size: 1.25rem;

    .radio {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export default function RadioButton({name, value, label, handleClick}) {

  return (
    <StyledLabel>
      <input type='radio' name={name} value={value} onChange={() => handleClick(value)} />
      <div className='radio'><div></div></div>
      {label}
    </StyledLabel>
  );
}