import { AnimatePresence } from 'framer-motion';

import Backdrop from 'components/modals/components/Backdrop';
import Card from 'components/modals/components/Card';

import { modal } from 'styles/MotionVariants';

const { backdrop, card } = modal;


export default function BaseModal({ showModal, setModal, children }) {
  return (
    <AnimatePresence>
      {showModal && (
        <Backdrop
          onClick={() => setModal(false)}
          initial={backdrop.initial}
          animate={backdrop.animate}
          exit={backdrop.exit}
        >
          <Card
            onClick={(ev) => ev.stopPropagation()}
            initial={card.initial}
            animate={card.animate}
            exit={card.exit}
          >
            {children}
          </Card>
        </Backdrop>
      )}
    </AnimatePresence>
  );
}
