import styled from 'styled-components';

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserPlus, faUserCheck, faUserMinus, faUserTimes } from '@fortawesome/free-solid-svg-icons';

import Spinner from 'atoms/Spinner';
import IconButton from 'atoms/IconButton';
import Button from 'atoms/Button';
import Header from 'components/Header';
import MessageBox from 'components/MessageBox';
import FriendCard from 'components/FriendCard';
import AddFriend from 'components/modals/AddFriend';
import Confirm from 'components/modals/Confirm';

import { requests } from 'utilities/Api';
import { AuthContext }from 'utilities/AuthContext';
import { opacity } from 'styles/MotionVariants';


const { container, item, animate, initial } = opacity;

export default function Friends() {
  const { setPopupMessage } = useContext(AuthContext);

  const [friends, setFriends] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalRemoveFriend, setModalRemoveFriend] = useState(null);

  useEffect(() => {
    updateFriends();
  }, []);

  async function updateFriends() {
    let response;
    setLoading(true);

    try {
      response = (await requests.get('friends')).data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
      return;
    }

    setFriends(response);
    setLoading(false);
  }

  async function addFriend(username) {
    let response;
    try {
      response = (await requests.post('friends', { username })).data;
    } catch (err) {
      console.log(err);
      setPopupMessage(err.response.data);
      return;
    }

    setPopupMessage(response);
    setShowModal(false);
    updateFriends();
  }

  async function removeFriend(username) {
    let response;

    try {
      response = (await requests.delete(`friends?username=${username}`)).data;
    } catch (err) {
      console.log(err);
      setPopupMessage(err.response.data.message);
      return;
    }

    setPopupMessage(response);
    updateFriends();
  }

  function handleClickAdd(ev) {
    ev.preventDefault();

    addFriend(ev.currentTarget.value);
  }

  return (
    <>
      <Header title='Friends'
        leftButton={(<Link to='/account'><IconButton icon={<FontAwesomeIcon icon={faArrowLeft} color='#fff' transform='grow-10' />} /></Link>)}
        rightButton={(<Button title={<FontAwesomeIcon icon={faUserPlus} size='lg' />} handler={() => setShowModal(prev => !prev)} />)}
      />
      <Container friends={friends} loading={loading} error={error} 
        addFriend={addFriend} removeFriend={removeFriend}
        handleClickAdd={handleClickAdd} setModalRemoveFriend={setModalRemoveFriend}
      />
      <AddFriend showModal={showModal} setShowModal={setShowModal} addFriend={addFriend} />
      <Confirm showModal={modalRemoveFriend} setModal={setModalRemoveFriend} handler={removeFriend}>
        Are you sure you wish to remove <strong>{modalRemoveFriend}</strong> from your friends list?
      </Confirm>
    </>
  );
}


const StyledContainer = styled.article`
  .section {
    margin-bottom: 2rem;
    
    .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      h2 {
        color: var(--clr-text-primary);
        font-size: 1.5rem;
        flex: 1;
      }

      span {
        font-size: 1.15rem;
        color: var(--clr-text-secondary);
        background: var(--clr-background-secondary);
        padding: 0.25rem 1rem;
        border-radius: 100px;
      }
    }

    ul {
      display: grid;
      list-style: none;
      grid-template-columns: 1fr;
      gap: 1rem;

      @media (min-width: 1020px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

function Container({ friends, loading, error, handleClickAdd, removeFriend, setModalRemoveFriend }) {
  if (loading) {
    return (<Spinner />);
  }

  if (error) {
    return (
      <MessageBox title='Error'>
        Something went wrong.
      </MessageBox>
    );
  }
  
  const { friends: myFriends, requests: { received, sent }} = friends || { friends: [], requests: { sent: [], received: [] }};

  return (
    <StyledContainer>
      {!!sent.length && (
        <section className='section'>
          <motion.div className='title-container' initial={initial} animate={animate}>
            <h2>Sent requests</h2>
          </motion.div>
          <motion.ul variants={container} initial={initial} animate={animate}>
            {sent.map(friend => (
              <motion.li key={friend.username} variants={item}>
                <FriendCard name={friend.name} username={friend.username} avatar={friend.avatar}>
                  <Button type='danger' handler={() => removeFriend(friend.username)} title={<FontAwesomeIcon icon={faUserTimes} />} />
                </FriendCard>
              </motion.li>
            ))}
          </motion.ul>
        </section>
      )}

      {!!received.length && (
        <section className='section'>
          <motion.div className='title-container' initial={initial} animate={animate}>
            <h2 initial={initial} animate={animate}>Received requests</h2>
            <span>{received.length}</span>
          </motion.div>
          <motion.ul variants={container} initial={initial} animate={animate}>
            {received.map(friend => (
              <motion.li variants={item}>
                <FriendCard key={friend.username} name={friend.name} username={friend.username} avatar={friend.avatar}>
                  <Button type='danger' handler={() => removeFriend(friend.username)} title={<FontAwesomeIcon icon={faUserTimes} />} />
                  <Button value={friend.username} handler={handleClickAdd} title={<FontAwesomeIcon icon={faUserCheck} />} />
                </FriendCard>
              </motion.li>
            ))}
          </motion.ul>
        </section>
      )}
      <section className='section'>
        <motion.div className='title-container' initial={initial} animate={animate}>
          <h2>Your friends</h2>
          <span>{myFriends.length}</span>
        </motion.div>
        {!myFriends.length
          ? (
            <MessageBox title='Empty'>
              You have no friends added. Send a friend request to someone by
              clicking on the button above!
            </MessageBox>
            )
          : (
              <motion.ul variants={container} initial={initial} animate={animate}>
                {myFriends.map(friend => (
                  <motion.li variants={item}>
                    <FriendCard key={friend.username} name={friend.name} username={friend.username} avatar={friend.avatar}>
                      <Button type='danger' handler={() => setModalRemoveFriend(friend.username)} title={<FontAwesomeIcon icon={faUserMinus} />} />
                    </FriendCard>
                  </motion.li>
                ))}
              </motion.ul>
        )}
      </section>
    </StyledContainer>
  );
}