import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
  --clr-background-back: #222629;
  --clr-background-front: #474B4F;
  --clr-background-secondary: #6B6E70;

  --clr-text-primary: #fff;
  --clr-text-secondary: #ccc;

  --font-family: 'Roboto', sans-serif;
  }

  html {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    background: var(--clr-background-back);
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

export default GlobalStyle;