import styled from 'styled-components';

import { motion } from 'framer-motion';

const StyledTitle = styled(motion.h1)`
  font-size: 1.5rem;
  color: var(--clr-text-primary);

  @media (min-width: 600px) {
    font-size: 2rem;
  }
`;

export default StyledTitle;