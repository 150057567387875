import styled from 'styled-components';

import { motion } from 'framer-motion';

const StyledButton = styled(motion.button)`
  letter-spacing: 0.5px;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0.5rem 1.25rem;
  background: #777;
  color: var(--clr-text-primary);
  border: none;
  border-radius: 30px;
  font-family: var(--font-family);
  cursor: pointer;
  transition: background-color 100ms;

  @media (min-width: 600px) {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  &.primary {
    background: #61892F;
  }

  &.secondary {
    background: #858585;
  }

  &.danger {
    background: #892F2F;
  }

  &:disabled {
    background: #282828;
  }
`;

export default function Button({title, handler, type = 'primary', disabled = false, value}) {

  return (
    <StyledButton 
      className={type} 
      onClick={handler} 
      disabled={disabled} 
      value={value}
      initial={{scale: 0}}
      animate={{scale: 1}}
      whileHover={{scale: 1.1}}
      whileTap={{scale: 0.9}}
    >
      {title}
    </StyledButton>
  );
}