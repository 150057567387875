import styled, { keyframes } from 'styled-components';

import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion'; 

const duration = 2000;

const progress = keyframes`
  from {
    right: 100%;
  }
  to {
    right: 0;
  }
`;

const StyledContainer = styled(motion.div)`
  position: fixed;
  top: 1rem;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
  pointer-events: none;

  .card {
    padding: 0.75rem 1rem;
    background: #fff;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    border: 2px solid white;
    font-size: 0.9rem;
    max-width: min(90%, 25rem);

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      background-color: #333;
      animation: ${progress} ${duration}ms linear;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: 600px) {
    padding-left: ${({authenticated}) => authenticated ? '125px': '0'};
  }
`;

export default function PopupMessage({message, setMessage, authenticated }) {

  useEffect(() => {
    setTimeout(() => setMessage(null), duration);
  });

  return createPortal(
    <AnimatePresence>
      {message && (
        <StyledContainer
          initial={{opacity: 0, x: '-40%'}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: '40%'}}
          className='nice'
          authenticated={authenticated}
        >
          <div className='card'>
            {message}
          </div>
        </StyledContainer>
      )}
    </AnimatePresence>,
    document.getElementById('popup-message')
  );
}