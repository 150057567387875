import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserCircle, faArrowRight, faKey } from '@fortawesome/free-solid-svg-icons';

import IconButton from '../atoms/IconButton';
import MessageBox from '../components/MessageBox';
import Header from '../components/Header';


const StyledList = styled.ul`
  display: grid;
  gap: 1rem;

  .item {
    padding: 1rem;
    background: var(--clr-background-front);
    border-radius: 10px;
    text-decoration: none;
    color: var(--clr-text-primary);
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      flex: 1;
    }
  }
`;

export default function Settings() {
  return (
    <>
      <Header title='Settings'
        leftButton={(<Link to='/account'><IconButton icon={<FontAwesomeIcon icon={faArrowLeft} color='#fff' transform='grow-10' />} /></Link>)}
      />
      <MessageBox title='Coming soon!'>
        This page is not ready yet. But it will be very soon!
      </MessageBox>
      <StyledList>
        <li>
          <Link to='avatar'>
            <motion.div className='item' initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <FontAwesomeIcon icon={faUserCircle} size='lg' />
              <p>Change avatar</p>
              <FontAwesomeIcon icon={faArrowRight} size='lg' />
            </motion.div>
          </Link>
        </li>
        <li>
        <Link to='password'>
            <motion.div className='item' initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <FontAwesomeIcon icon={faKey} size='lg' />
              <p>Change password</p>
              <FontAwesomeIcon icon={faArrowRight} size='lg' />
            </motion.div>
          </Link>
        </li>
      </StyledList>
    </>
  );
}