import styled from 'styled-components';

import { motion } from 'framer-motion';

const StyledButton = styled(motion.button)`
  background: none;
  border: none;
  padding: 0 0.5rem;
`;

export default function IconButton({icon, handler}) {
  return (
    <StyledButton
      onClick={handler}
      whileHover={{scale: 1.2}}
      whileTap={{scale: 0.9}}
    >
      {icon}
    </StyledButton>
  );
}