import styled from 'styled-components';
import { motion } from 'framer-motion';

const Card = styled(motion.div)`
  width: 300px;
  background: var(--clr-background-front);
  color: var(--clr-text-primary);
  padding: 1rem 1rem;
  border-radius: 10px;

  h2 {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 600px) {
    .card {
      padding: 1.5rem;
      width: 400px;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
`;

export default Card;