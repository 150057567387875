import styled from 'styled-components';

import Avatar from '../atoms/Avatar';

const StyledContainer = styled.div`
  border-radius: 10px;
  padding: 1rem;
  background: var(--clr-background-front);
  .info-container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .info {
    flex: 1;
  }

  .name {
    font-size: 1.25rem;
    color: var(--clr-text-primary);
  }

  .username {
    font-size: 1rem;
    color: var(--clr-text-secondary);
  }
  
  .button-container {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }


`;

export default function FriendCard({ name, username, avatar, children }) {
  return (
    <StyledContainer>
      <div className='info-container'>
        <Avatar avatar={avatar} size='small' />
        <div className='info'>
          <p className='name'>{name}</p>
          <p className='username'>{username}</p>
        </div>
      </div>
      {children &&  (
        <div className='button-container'>
          {children}
        </div>
      )}
    </StyledContainer>
  );
}