import styled from 'styled-components';
import { motion } from 'framer-motion';

const Backdrop = styled(motion.div)`
    position: fixed;
    inset: 0 0 0 0;
    display: grid;
    place-items: center;
    background: #222629aa;
    z-index: 1;

`;

export default Backdrop;