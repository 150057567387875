import styled from 'styled-components';

import { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faEllipsisV, faHistory, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Header from 'components/Header';
import IconButton from 'atoms/IconButton';
import MovieCard from 'components/MovieCard';
import Button from 'atoms/Button';
import Spinner from 'atoms/Spinner';
import MessageBox from 'components/MessageBox';
import EditList from 'components/modals/EditList';
import Confirm from 'components/modals/Confirm';

import { requests } from 'utilities/Api';

import { AuthContext } from 'utilities/AuthContext';
import DropdownMenu, { DropdownItem } from 'components/DropdownMenu';

const StyledDescription = styled(motion.p)`
  color: var(--clr-text-secondary);
  font-size: 1rem;

  @media (min-width: 600px) {
    font-size: 1.15rem;
  }
`;


export default function List() {
  const { id } = useParams();
  const { setPopupMessage } = useContext(AuthContext);
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: '...',
    description: '',
    movies: []
  });

  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [confirmDeleteList, setConfirmDeleteList] = useState(null);

  useEffect(() => {
    updateMovies();
  }, []);

  async function updateMovies() {
    setLoading(true);

    let response;

    try {
      response = (await requests.get(`playlists/${id}`)).data;
    } catch(err) {
      setLoading(false);
      setInfo({name: '', description: '', movies: []});
    }

    setLoading(false);
    setInfo({
      name: response.info.name,
      description: response.info.description,
      movies: response.movies
    });
  }
  
  async function markWatched(ev) {
    ev.preventDefault();

    let response;
    
    const movieId = ev.currentTarget.value;
    
    try {
      response = (await requests.post('/watched', {movie: movieId})).data;
    } catch(err) {
      console.log('error:', err);
      return;
    }
     
    setPopupMessage(response);
    setInfo(prev => ({
      name: prev.name,
      description: prev.description,
      movies: prev.movies.map(movie => {
        if (movie.id !== movieId) {
          return movie;
        } else {
          return {
            ...movie,
            watched: !movie.watched
          };
        }
      })
    }));
  }

  async function removeMovie(info) {
    let response;

    try {
      response = (await requests.delete(`playlists/${info.list}?movie=${info.movie}`)).data;
    } catch(err) {
      console.log('error:', err.response);
      return;
    }

    setPopupMessage(response);
    // updateMovies();
    setInfo(prev => ({
      name: prev.name,
      description: prev.description,
      movies: prev.movies.filter(movie => movie.id !== info.movie)
    }));
  }

  async function deleteList(info) {
    let response;

    try {
      response = (await requests.delete(`playlists?list=${info.id}`)).data;
    } catch(err) {
      console.log('error:', err);
      return;
    }

    setPopupMessage(response);
    navigate('/lists');
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.01
      }
    },
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    remove: {
      opacity: 0,
      x: 300,
      height: 0,
    }
  }

  return (
    <>
      <Header title={info.name} key={info.name}
        leftButton={(<Link to='/lists'><IconButton icon={<FontAwesomeIcon icon={faArrowLeft} color='#fff' transform='grow-10' />} /></Link>)}
        rightButton={<IconButton icon={<FontAwesomeIcon icon={faEllipsisV} color='#fff' transform='grow-10' />} handler={() => setShowDropdown(prev => !prev)} />}
      >
        {!info.description
          ? null
          : <StyledDescription
              variants={container}
              initial='hidden'
              animate='show'
            >
              {info.description.split('').map((letter, index) => (
                <motion.span
                  key={`${letter}${index}`}
                  variants={item}
                >
                  {letter}
                </motion.span>
              ))}
            </StyledDescription>
        }
        <AnimatePresence>
          {showDropdown && (
            <DropdownMenu>
              <DropdownItem title='Edit list info' handler={() => setShowModal(true)} />
              <DropdownItem title='Delete list' handler={() => setConfirmDeleteList({id: id})} />
            </DropdownMenu>
          )}
        </AnimatePresence>
      </Header>
      {loading
        ? <Spinner />
        : !info.movies.length
          ? <MessageBox title='Empty list'>Try searching for a movie on the <Link to='/search'>Search page</Link>!</MessageBox>
          : (<motion.ul variants={container} initial='hidden' animate='show'>
              <AnimatePresence>
                {info.movies.map(movie => (
                  <motion.li key={movie.id} variants={item} exit={item.remove}>
                    <MovieCard info={movie}>
                      <a href={'https://imdb.com/title/' + movie.id} target='_blank' rel='noreferrer'><Button title='IMDb' /></a>
                      <Button title={<FontAwesomeIcon icon={faTrashAlt} transform='grow-6' />} value={movie.id} handler={(ev) => setConfirmRemove({list: id, movie: ev.currentTarget.value})} type='danger' />
                      <Button title={<FontAwesomeIcon icon={movie.watched ? faCheck : faHistory} transform='grow-6' />} value={movie.id} handler={markWatched} type='secondary' />              
                    </MovieCard>
                  </motion.li>
                ))}
              </AnimatePresence>
            </motion.ul>)
      }

      {!loading && <EditList listId={id} listInfo={info} updateList={updateMovies} showModal={showModal} setModal={setShowModal} />}
      <Confirm showModal={confirmRemove} setModal={setConfirmRemove} handler={removeMovie}>
        Are you sure you want to remove this movie?
      </Confirm>
      <Confirm showModal={confirmDeleteList} setModal={setConfirmDeleteList} handler={deleteList}>
        Are you sure you want to delete this list and all its contents?
      </Confirm>
    </>
  );
}
